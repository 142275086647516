function toggleNav(event) {
  const menu = document.getElementById('menu');
  const nav = document.getElementById('nav');

  // allow links in the nav to work
  if (!menu.contains(event.target) && event.target !== nav) return;

  if (menu.getAttribute('aria-expanded') === 'false') {
    menu.setAttribute('aria-expanded', 'true');
    // can close navigation by clicking on dark bg
    nav.addEventListener('click', toggleNav, false);
  } else {
    menu.setAttribute('aria-expanded', 'false');
    nav.removeEventListener('click', toggleNav, false);
  }
  menu.classList.toggle('is-open');
  nav.classList.toggle('is-open');
}

export default function initNav() {
  const menu = document.getElementById('menu');
  menu.addEventListener('click', toggleNav, false);
}
